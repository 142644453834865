<template>
    <section>
        <Breadcrumbs/>

        <div class="about">
            <div>
                <div class="about__text">
            <h1>About</h1>
                    {{ about.text }}
                </div>
                
                <div class="about__image">
                    <img :src="about.image" alt="about image">
                </div>

            </div>
        </div>


        <ul class="faq">
            <li
            ref="answer"
            v-for="{answer, question, id} in faq" 
            :key="id" 
            @click="toggleTab(id)" 
            :class="{active: current == id}"
            >
                <h3>{{ question }} <div class="faq__toggle"><span></span></div></h3>
                <div class="faq__answer"> {{ answer }}</div>
            </li>
        </ul>
        
    </section>
</template>
<script>
// import { mapState } from 'vuex';
import { AboutService, FaqService } from "@/api";



export default {
    name: 'Faq',
    data() {
        return {
            faq: Array,
            about: Object,
            current: -1
        }
    },
    created() {
        this.getFAQ();
        this.getAbout();
    },
    methods: {
        async getFAQ() {
            await FaqService.getAll()
                .then(res => {        
                    this.faq = res;
                    this.$nextTick(() => {
                         this.initTabs();
                    })
                })
        },
        async getAbout() {
            await AboutService.getAll()
                .then(res => {        
                    this.about = res[0];
                })
        },
        initTabs() {
            Array.from(this.$refs.answer).forEach(answer => {
                const el = answer.children[1];
                el.style.height = el.getBoundingClientRect().height + 'px';
                el.style.transition = `height ${0.2 + el.innerText.length / 1000}s`;
                el.classList.add('closed');
            })
        },
        toggleTab(id) {
            this.current === id ? this.current = -1 : this.current = id;
        }
    }
}
</script>
<style lang="scss" scoped>
    section {
        @include sectionStarter;
        margin-top: 8rem;
        padding-top: 4rem;
        min-height: calc(100vh - 130px);



        & h1 {
            @include fontPoppins;
            color: $lightText;
            font-size: 3rem;
            letter-spacing: .1em;
            z-index: 1;
            margin: 0px 0px 2rem;
        }

        .about {
            margin:8rem 0px 4rem;
            & > div {
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                gap: 2rem;

                @include mq($from: tablet) {
                    flex-direction: row;
                }

                @include mq($from: desktop) {
                    gap: 10rem;
                }
            }

            &__text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                @include fontMontserrat;
                color: $middleText;
                font-size: 1.375rem;
                line-height: 1.75;

                @include mq($from: tablet) {
                    width: 50%;
                    font-size: 1.5rem;
                    line-height: 1.6;

                }
            }

            &__image {
                display: flex;
                align-items: flex-end;

                @include mq($from: tablet) {
                    width: 50%;
                }
                
                img {
                    width: 100%;
                }
            }
        }

        .faq {
            color: white;
            background-color: rgba(0, 0, 0, 0.2);
            padding: 3rem 0px;
            border-radius: 2rem;
            margin: 4rem 0px;



            &__toggle {
                position: absolute;
                top: 50%;
                right: 4%;
                width: 3.2rem;
                height: 3.3rem;
                background-color: rgba(255, 255, 255, 0.04);
                border-radius: 50%;
                transform: translateY(-50%);
                display: grid;
                place-items: center;
                cursor: pointer;

                & span {
                    position: relative;
                    width: 1px;
                    height: 1.50rem;
                    background: $main;
                    transform: rotate(-90deg);
                    transition: transform .22s, color .32s;
                    

                    &::before, &::after {
                        content: '';
                        position: absolute;
              
                        width: 1px;
                        height: 1.5rem;
                        background: $main;
                        transition: transform .32s, color .32s;
                    }
                }

            }

            li {
                list-style: none;
                font-weight: 300;
                @include fontMontserrat;
                font-weight: 300;
                color: $middleText;
                padding: 0px 4%;
                margin: 3rem 0px;
                cursor: pointer;
                -webkit-tap-highlight-color: rgba(255, 255, 255, 0.03);

                &:hover span {
                    &::before {
                        transform: translate(3px, 0px) rotate(-90deg) scaleY(0.5);
                    }

                    &::after {
                        transform: translate(-3px, 0px) rotate(-90deg) scaleY(0.5);
                    }
                }

                &.active {
                    h3 {
                        color: white;
                    }
                        span {
                            transform: rotate(0deg);
                        }

                         &:hover span {
                            &::before {
                                transform: translate(-2px, -7px) rotate(45deg) scaleY(0.5);
                            }

                            &::after {
                                transform: translate(2px, -7px) rotate(-45deg) scaleY(0.5);
                            }
                        }
                }

                h3 {
                    position: relative;
                    padding: 1rem 0px;
                    font-size: 1.625rem;
                    line-height: 1.5;
                    font-weight: 300;
                    padding-right: calc(12% + 2rem);
                    transition: color .22s;
                }

                &.active {
                    .faq__toggle {
                        // border: 1px solid red;
                        span, span::before, span::after {
                            background-color: $white;
                        }
                    }
                }
            }


            &__answer {
                overflow: hidden;
                transition: height .3s;
                line-height: 1.75;
                padding-right: calc(12% + 2rem);


                &.closed:not(.active .faq__answer.closed ) {
                    height: 0px!important;
                }
            }

            
        }
    }
</style>